import { PassportPageType } from '../../lib/api/custom-page.types';
import { RoutesConfig } from './index';
import {
  NOT_FOUND_PATH,
  ROOT,
  WELCOME_PATH,
  AGENDA_PATH,
  SESSION_PATH,
  SESSION_MEETING_PATH,
  AGENDA_MEETING_PATH,
  SHOWCASE_PATH,
  SUPPORT_PATH,
  REGISTRATION_PATH,
  CONNECTION_KNOVIO_PATH,
  SPEAKERS_PATH,
  DefaultPathLabel,
  TRIAGE_ANSWER_REGISTRATION_PATH,
  REPLAYS_PATH,
  BTS_REGISTRATION_PATH
} from './paths';
import { PassportPlaceholder } from '../../app/passport/PassportPlaceholder';
import NotFoundPage from '../../app/passport/404';
import Welcome from '../../app/passport/WelcomeWrapper/WelcomeWrapper';
import AgendaWrapper from '../../app/passport/AgendaWrapper/AgendaWrapper';
import SessionWrapper from '../../app/passport/SessionWrapper/SessionWrapper';
import MeetingWrapper from '../../app/passport/MeetingWrapper/MeetingWrapper';
import ShowcaseWrapper from '../../app/passport/ShowcaseWrapper/ShowcaseWrapper';
import KnovioPageWrapper from '../../app/passport/KnovioPageWrapper';
import SupportPageWrapper from "../../app/passport/SupportPageWrapper/SupportPageWrapper";
import { RegistrationPage } from '../../app/passport/RegistrationPage';
import SpeakerWrapper from '../../app/passport/SpeakerWrapper/SpeakerWrapper';
import ReplaysWrapper from '../../app/passport/ReplaysWrapper/ReplaysWrapper';
import BTSRegistrationForm from '../../app/passport/BTS/BTSTestRegistrationPage';

const routes: RoutesConfig = {
  [ ROOT ]: { exact: true, component: PassportPlaceholder, label: DefaultPathLabel.SIGN_IN },

  [ WELCOME_PATH ]: { component: Welcome, _protected: true, type: PassportPageType.WELCOME },
  [ AGENDA_PATH ]: { exact: true, component: AgendaWrapper, _protected: true, type: PassportPageType.AGENDA },
  [ SESSION_PATH ]: { exact: true, component: SessionWrapper, _protected: false, type: PassportPageType.SESSIONS },
  [ SESSION_MEETING_PATH ]: { component: MeetingWrapper, _protected: true, type: PassportPageType.SESSIONS },
  [ AGENDA_MEETING_PATH ]: { component: MeetingWrapper, _protected: true, type: PassportPageType.AGENDA },
  [ SHOWCASE_PATH ]: { component: ShowcaseWrapper, _protected: true, type: PassportPageType.SHOWCASE },
  [ SPEAKERS_PATH ]: { component: SpeakerWrapper, _protected: false, type: PassportPageType.SPEAKERS },
  [ SUPPORT_PATH ]: { component: SupportPageWrapper, _protected: true, type: PassportPageType.SUPPORT },
  [ REPLAYS_PATH ]: { component: ReplaysWrapper, _protected: true, type: PassportPageType.REPLAYS },
  [ CONNECTION_KNOVIO_PATH ]: { component: KnovioPageWrapper, _protected: true, label: DefaultPathLabel.CONNECTION_KNOVIO_PATH_LABEL },

  [ NOT_FOUND_PATH ]: { component: NotFoundPage, label: DefaultPathLabel.NOT_FOUND_PATH_LABEL },
  [ REGISTRATION_PATH ]: { exact: true, component: RegistrationPage, _public: true, label: DefaultPathLabel.REGISTRATION },
  [ TRIAGE_ANSWER_REGISTRATION_PATH ]: { exact: false, component: RegistrationPage, _public: true, label: DefaultPathLabel.REGISTRATION },
  [ BTS_REGISTRATION_PATH ]: { exact: true, component: BTSRegistrationForm }
};

export default routes;

export const routesShouldBeInCustomRoutes = [NOT_FOUND_PATH, CONNECTION_KNOVIO_PATH];
